import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=5f4ad05a&"
import script from "./edit.vue?vue&type=script&lang=js&"
export * from "./edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f4ad05a')) {
      api.createRecord('5f4ad05a', component.options)
    } else {
      api.reload('5f4ad05a', component.options)
    }
    module.hot.accept("./edit.vue?vue&type=template&id=5f4ad05a&", function () {
      api.rerender('5f4ad05a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/scmt-vue-template/new-window/edit.vue"
export default component.exports